<template>
    <p v-if="isDebt" class="text-align-center color-error border-color-error message border padding-m max-width-l margin-horizontal-auto">
        Зверніть увагу: у вас наявна заборгованість.
    </p>
</template>

<script>
 export default {
     props: [
         'isDebt',
     ]
 }
</script>
