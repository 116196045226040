<template>
    <div id="nav" class="main-nav">
        <nav v-if="isLoggedIn">
            <div class="logo">
                <img src="@/assets/logo.png" alt="Світ Кави">
            </div>
            <ul class="pages">
                <li>
                    <router-link to="/order/select"><img class="icon" src="@/assets/icons/icon-subscription.svg" alt=""/> <span>Замовити каву</span></router-link>
                </li>
                <li>
                    <router-link to="/orders"><img class="icon" src="@/assets/icons/icon-bundle.svg" alt=""> <span>Історія замовлень</span></router-link>
                </li>
                <li>
                    <router-link to="/profile"><img class="icon" src="@/assets/icons/icon-avatar.svg" alt=""> <span>Профіль</span></router-link>
                </li>
            </ul>
            <div class="logout" v-if="isLoggedIn">
                <button class="logout" @click="logout">Вийти</button>
            </div>
        </nav>
        <div v-else class="flex-justify justify-content-center logo padding-m">
            <img src="@/assets/logo.png" alt="Світ кави">
        </div>
    </div>
    <router-view/>
</template>

<script>
 import { ref } from 'vue';
 import { isLoggedIn } from 'axios-jwt';
 import { logout } from '@/common/api.js';
 import router from '@/router/index.js';

 const isLoggedInRef = ref(false);

 router.afterEach((to, from) => isLoggedInRef.value = isLoggedIn());

 export default {
     setup () {
         return { isLoggedIn: isLoggedInRef, logout }
     },
 }
</script>

<style lang="scss">
 /*! Based on Font Squirrel (https://www.fontsquirrel.com) on July 30, 2018 */
 @font-face {
     font-family: 'Eurofurence Light';
     src: url('~@/assets/fonts/eurof35.woff2') format('woff2');
     font-weight: normal;
     font-style: normal;
 }
 @font-face {
     font-family: 'Eurofurence';
     src: url('~@/assets/fonts/eurof55.woff2') format('woff2');
     font-weight: normal;
     font-style: normal;
 }
 @import "~@fontsource/roboto/300.css";
 @import "~@fontsource/roboto/400.css";
 @import "~turretcss/dist/turretcss.min.css";
 @import "~@/sass/common";

 body {
     font-family: 'Roboto';
 }

 h1, h2, h3, .like-h {
     font-family: "Eurofurence Light";
     font-weight: normal;
     text-transform: uppercase;
     letter-spacing: $letter-spacing-1;
 }

 .xbutton {
     @include strong-button($red, white);
     text-transform: uppercase;
     border-radius: 0;
     letter-spacing: $letter-spacing-1;
 }
 .flat-button {
     border-radius: 0;
     background: $light-gray;
     height: auto;
     font-weight: normal;
     display: block;
     &.red {
         color: $red;
     }
     @media (min-width: $breakpoint-1) {
         font-size: 2rem;
     }
 }

 .main-nav {
     ul {
         font-size: 85%;
         display: flex;
         flex-flow: row nowrap;
         justify-content: space-around;
     }
     a {
         display: flex;
         flex-flow: column;
         align-items: center;
         text-decoration: none;
         text-transform: uppercase;
         letter-spacing: $letter-spacing-1;
         opacity: 0.7;
         padding-right: $small-gap;
         text-align: center;
     }
     .icon {
         max-width: 4rem;
     }
     .logo img {
         max-width: 6rem;
     }
     .router-link-active {
         opacity: 1;
     }
     margin-bottom: 1.5rem;
     nav {
         display: grid;
         grid-template-columns: 1fr 1fr;
         grid-template-areas: "logo logout" "nav nav";
         grid-gap: $small-gap;
         padding: $small-gap;
         align-items: center;
         .pages {
             grid-area: nav;
             li {
                 margin: 0;
             }
         }
         .logo {
             grid-area: logo;
         }
         .logout {
             grid-area: logout;
             justify-self: end;
         }
         @media (min-width: $breakpoint-1) {
             grid-template-columns: auto 1fr auto;
             grid-template-areas: "logo nav logout";
             a {
                 flex-flow: row;
             }
             .icon {
                 margin-right: $small-gap;
             }
         }
     }
 }

 .address-form-block {
     @media (min-width: 40rem) {
         display: grid;
         grid-template-columns: 2fr 1fr;
         grid-gap: 1rem;
         > .field {
             margin: 0;
             &.street-address {
                 grid-column: 1 / -1;
             }
         }
     }
 }
 .summary-actions {
     display: flex;
     flex-flow: row;
     justify-content: space-between;
     align-items: center;
 }

 details {
     summary {
         cursor: pointer;
     }
 }

 .like-label {
     @include like-label;
 }
 .red {
     color: $red;
 }

 @media (max-width: 400px) {
     body {
         font-size: 87%;
     }
 }

 dl > dt {
     @extend .like-h;
 }
</style>
