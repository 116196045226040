import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Checkout from '../views/Checkout.vue'
import Confirm from '../views/Confirm.vue'
import Login from '../views/Login.vue'
import Profile from '../views/MyProfile.vue'

const routes = [
  {
    path: '/',
    redirect: {
      name: 'select',
    },
  },
  {
    path: '/login/',
    name: 'login',
    component: Login
  },
  {
    path: '/order/select',
    name: 'select',
    component: Home
  },
  {
    path: '/order/checkout',
    name: 'checkout',
    component: Checkout
  },
  {
    path: '/order/confirm',
    name: 'confirm',
    component: Confirm
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile
  },
  {
    path: '/orders',
    name: 'orders',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MyOrders.vue')
  }
  // {
  //   path: '/loader',
  //   name: 'Історія замовлень',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
