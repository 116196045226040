<template>
    <DebtWarning v-if="p.last.isFinished" :is-debt="p.last.value.isDebt"/>

    <h2 class="text-align-center">Профіль</h2>

    <div class="profile margin-xl" v-if="p.last.isFinished">
        <div>
            <h2 class="like-label red">Дані профілю</h2>
            <dl>
                <dt>Логін</dt>
                <dd>{{ p.last.value.user.username }}</dd>
                <dt>Ім'я</dt>
                <dd>{{ p.last.value.name }}</dd>
                <dt>Телефон</dt>
                <dd>{{ p.last.value.phone }}</dd>
                <dt>Email</dt>
                <dd>{{ p.last.value.user.email }}</dd>
            </dl>
        </div>
        <div>
            <h2 class="like-label red">Комерційна інформація</h2>
            <dl>
                <dt>Індивідуальна знижка</dt>
                <dd>{{ p.last.value.discount }}%</dd>
                <dt>Обладнання в користуванні</dt>
                <dd>{{ p.last.value.gear ? p.last.value.gear : '-' }}</dd>
                <dt>Заявка на звірку</dt>
                <dt><button class="button button-grey" v-bind:disabled="crossCheckTask.isError || crossCheckTask.last?.isFinished" @click="confirmCrossCheck">
                    <span v-if="crossCheckTask.isRunning" class="spinner"></span>
                    <span v-else-if="crossCheckTask.isError">Помилка подачі заявки</span>
                    <span v-else-if="crossCheckTask.last?.isFinished">Заявку надіслано</span>
                    <span v-else>Подати заявку</span>
                </button></dt>
            </dl>
        </div>
    </div>
</template>

<script>
 import { getMyProfileTask, crossCheckTask } from '@/common/api.js';

 export default {
     setup () {
         getMyProfileTask.perform();

         const confirmCrossCheck = () => {
             if (confirm("Подати заявку на акт звірки? Про результат вам буде повідомлено електронною поштою."))
                 crossCheckTask.perform();
         };

         return {
             p: getMyProfileTask,
             crossCheckTask,
             confirmCrossCheck,
         }
     }
 }
</script>

<style lang="scss">
 @import "~@/sass/common";

 .profile {
     display: grid;
     grid-gap: $base-gap;
     text-align: center;
     @media (min-width: $breakpoint-1) {
         grid-template-columns: 1fr 1fr;
     }
     dt {
         @include like-label;
     }
     dd {
         letter-spacing: $letter-spacing-1;
         color: $medium-gray;
     }
     .like-label {
         margin: 2rem 0;
     }
 }
</style>
