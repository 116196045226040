export function offersByKey () {
  return JSON.parse(localStorage.getItem("rowsByKey")) || {};
}

export function loadSelection (rowsByKey) {
  const stored = JSON.parse(localStorage.getItem('selected')) || {};
  let processed = {};
  for (const key in stored) {
    if (rowsByKey[key] != undefined) {
      processed[key] = stored[key];
    }
  }
  return processed;
}

export function clearSelection () {
  localStorage.removeItem('selected');
}

export function loadOrderDraft () {
  return JSON.parse(localStorage.getItem('order-draft')) || {};
}
export function storeOrderDraft (order) {
  localStorage.setItem('order-draft', JSON.stringify(order));
}

export function selectionList (rowsByKey, selected) {
  const entries = Object.entries(selected);
  return entries.map(([key, _]) => rowsByKey[key].item);
}

export function selectionForOrder (rowsByKey, selected) {
  const out = [];
  for (const key in selected) {
    out.push(Object.assign({}, selected[key], rowsByKey[key]))
  }
  return out;
}

export function selectionTotals (rowsByKey, selected, profile) {
  const entries = Object.entries(selected);
  if (entries.length) {
    const totals = entries.reduce((acc, entry) => {
      const [key, item] = entry;
      const full = rowSum(rowsByKey, entry);
      return {
        "full": acc.full + full,
        "sum": acc.sum + discounted(full, profile, rowsByKey[key].type),
      };
    }, {'full': 0, 'sum': 0});
    console.log(totals);
    return totals;
  } else {
    return null;
  }
}

export function rowSum (rowsByKey, entry) {
  const [key, item] = entry;
  const row = rowsByKey[key];
  return (
    (parsePrice(rowsByKey[key]["priceBig"]) * item.quantity.big)
      + (parsePrice(rowsByKey[key]["priceSmall"]) * item.quantity.small)
  );
}
export function discounted (price, profile, type) {
  if (type == 'drip')
    return price;
  else
    return Math.round(price * (100 - profile.discount) / 100);
}

function parsePrice(price) {
  return Number(price.replace(",", "."))
}

export function orderItems (order) {
  return order.content.map(line => line.item);
}
