<template>
    <DebtWarning v-if="profile.last.isFinished" :isDebt="profile.last.value.isDebt" />

    <div v-if="profile.last.isRunning">Завантаження...</div>
    <div v-else-if="profile.last.isError">Проблема завантаження даних: {{ profile.last.error }}</div>
    <div v-else>
        <CoffeeList :p="profile" />
    </div>
</template>

<script>
// @ is an alias to /src
 import CoffeeList from '@/components/CoffeeList.vue'
 import { getMyProfileTask } from '@/common/api.js';

 export default {
     name: 'Замовити каву',
     components: {
         CoffeeList
     },
     setup() {
         getMyProfileTask.perform();

         return {
             profile: getMyProfileTask
         }
     }
 }
</script>
