<template>
    <p class="discount text-align-center margin-xl">Індивідуальна знижка на каву: {{ p.last.value.discount }}%</p>
    <h1 class="text-align-center margin-m">Асортимент</h1>
    <div class="button-group button-group-block max-width-m margin-horizontal-auto padding-m">
        <button class="button" :class="{ 'button-grey': currentTab == 'specialty' }" @click="currentTab = 'specialty'">Specialty</button>
        <button class="button" :class="{ 'button-grey': currentTab == 'premium' }" @click="currentTab = 'premium'">Premium</button>
        <button class="button" :class="{ 'button-grey': currentTab == 'drip' }" @click="currentTab = 'drip'">Drip</button>
    </div>
    <p v-if="getMyOffersTask.isRunning" class="message">Завантаження...</p>
    <p v-else-if="getMyOffersTask.isError" class="message">Помилка завантаження асортименту: {{ getMyOffersTask.last.error }}</p>
    <template v-else>
        <details class="text-align-center bean-description">
            <summary v-if="isCoffee">Про зерно {{ currentTab }}</summary>
            <summary v-else>Про drip coffee bag</summary>
            <div class="content">
                {{ getMyOffersTask.last.value[currentTab + "Description"] }}
            </div>
        </details>
        <ul class="coffee-list" :class="{ 'drip': !isCoffee }">
            <div class="header">
                <span></span>
                <div class="secondary-grouper">
                    <span><img src="@/assets/icons/icon-coffee.svg" alt="Зерно" /></span>
                    <span v-if="isCoffee"><img src="@/assets/icons/icon-use.svg" alt="Застосування" /></span>
                    <span><img src="@/assets/icons/icon-cafes.svg" alt="Смакові характеристики" /></span>
                </div>
                <template v-if="isCoffee">
                    <span>1 кг<br><span class="font-size-xs">пачка</span></span>
                    <span>250 г<br><span class="font-size-xs">пачка</span></span>
                </template>
                <template v-else>
                    <span>1 коробка<br><span class="font-size-xs">8 пакетів</span> </span>
                    <span>1 пакет</span>
                </template>
                <span></span>
            </div>
            <li class="item" v-bind:class="{active: selected[key(row)]}" v-for="row in getMyOffersTask.last.value.data[currentTab]">
                <span class="title like-h column">
                    {{ row.item }}
                    <span class="exclusive-mark" v-if="row.exclusive">exclusive</span>
                </span>
                <details class="secondary-grouper" :ref="addRowRef">
                    <summary>Детальніше</summary>
                    <span v-if="isCoffee" class="variety column">
                        {{ row.variety }}
                    </span>
                    <span class="bean column">
                        {{ row.bean }}
                    </span>
                    <span class="taste column">
                        {{ row.taste }}
                    </span>
                </details>
                <template v-if="selected[key(row)]">
                    <span class="column">
                        <label class="hide-visually" v-if="isCoffee">1kg</label>
                        <label class="hide-visually" v-else="isCoffee">1 коробка</label>
                        <input type="number" v-model="selected[key(row)].quantity.big" min="0">
                    </span>
                    <span class="column">
                        <label class="hide-visually" v-if="isCoffee">250g</label>
                        <label class="hide-visually" v-else>1 пакет</label>
                        <input type="number" v-model="selected[key(row)].quantity.small" min="0">
                    </span>
                </template>
                <template v-else>
                    <span class="price column">
                        {{ discounted(row.priceBig, p.last.value, row.type) }}
                    </span>
                    <span class="price column">
                        {{ discounted(row.priceSmall, p.last.value, row.type) }}
                    </span>
                </template>
                <span v-if="selected[key(row)]" class="column">
                    <button v-on:click="deactivate(row)" class="flat-button red" aria-label="вилучити">&times;</button>
                </span>
                <span v-else class="column">
                    <button v-on:click="activate(row)" class="flat-button">+</button>
                </span>
            </li>
        </ul>
    </template>
    <footer class="footer padding-m no-padding-horizontal" v-if="totals?.sum > 0">
        <div class="selected-list">
            <h2>Обрано:</h2>
            {{ selectedList.join(", ") }}
        </div>
        <Totals v-bind:totals="totals" />
        <div>
            <router-link to="/order/checkout" class="button button-primary button-xxl xbutton">Замовити</router-link>
        </div>
    </footer>
</template>

<script>
 import { computed, reactive, ref, watch } from 'vue';
 import { getMyOffersTask } from '@/common/api.js';
 import { loadSelection, selectionList, selectionTotals, discounted } from '@/common/selection.js';
 import Totals from '@/components/Totals.vue';

 const rowDetails = ref([]);

 const resize = function () {
     const isMobile = window.innerWidth < 960;
     const nodes = rowDetails.value;
     nodes.forEach(el => isMobile ? el.removeAttribute("open") : el.setAttribute("open", ""));
 };

 const addRowRef = function(row) {
     if (row) rowDetails.value.push(row);
 };

 export default {
     components: {
         Totals,
     },
     props: ['p'],
     updated() {
         resize();
     },
     mounted() {
         resize();
     },
     beforeUpdate() {
         rowDetails.value = [];
     },
     setup(props) {
         const selected = ref({});
         const key = row => row.sku;
         const currentTab = ref('specialty');
         const isCoffee = computed(() => currentTab.value != 'drip');
         let rowsByKey = {};

         window.addEventListener("resize", resize);

         /* === operations === */
         const activate = row => {
             selected.value[key(row)] = {
                 quantity: {
                     big: 0,
                     small: 0,
                 },
             };
         };
         const deactivate = row => {
             delete selected.value[key(row)];
         };

         /* === derived === */
         const totals = computed(() => selectionTotals(
             rowsByKey,
             selected.value,
             props.p.last.value,
         ));

         const selectedList = computed(() => selectionList(rowsByKey, selected.value));

         /* === data === */
         getMyOffersTask.perform().then(data => {
             for (const group in data.data) {
                 data.data[group].forEach(
                     row => {
                         rowsByKey[key(row)] = Object.assign({}, row, {type: group});
                         row.type = group;
                     }
                 );
             }
             selected.value = loadSelection(rowsByKey);
             localStorage.setItem("rowsByKey", JSON.stringify(rowsByKey));
             console.log(rowsByKey)
         });

         /* === persistence === */
         watch(selected, (val, old) => {
             localStorage.setItem("selected", JSON.stringify(val));
         }, {deep: true});

         return {
             getMyOffersTask,
             currentTab,
             isCoffee,
             selected,
             discounted,
             key,
             activate,
             deactivate,
             totals,
             selectedList,
             addRowRef,
         };
     }
 }
</script>

<style lang="scss">
 @import "~@/sass/common";

 $breakpoint: 60rem;

 @media (max-width: $breakpoint) {
     .selected-list { display: none; }
 }

 .discount {
     color: $red;
     text-transform: uppercase;
     letter-spacing: $letter-spacing-1;
 }
 .bean-description {
     font-size: 85%;
     color: $dark-gray;
     > .content {
         padding: $small-gap;
     }
 }
 .coffee-list {
     font-size: 0.85rem;
     list-style: none;
     display: grid;
     align-items: stretch;
     grid-template-columns: 1fr 1fr 1fr auto;
     grid-auto-flow: dense;
     &.drip {
         grid-template-columns: 1fr 1fr 1fr auto;
     }
     .secondary-grouper {
         grid-column: 1 / -1;
         display: flex;
         flex-flow: row;
         z-index: 2;
         summary {
             padding: 1rem;
             cursor: pointer;
         }
     }
     > .item, > .header {
         display: contents;
     }
     .header {
         color: $red;
         * {
             justify-self: center;
             align-self: center;
             text-align: center;
         }
         img {
             max-width: 3rem;
         }
         .secondary-grouper {
             display: none;
         }
     }
     > .item {
         font-size: 85%;
         font-family: 'Roboto';
         font-weight: 400;
         letter-spacing: 0.1em;
         > .title {
             @extend .product-title;
         }
         .column {
             padding: 0.25rem;
             display: flex;
             flex-flow: column;
             justify-content: center;
             align-items: flex-start;
         }
         &.active .column {
             background: $light-gray;
             .flat-button {
                 background: white;
                 &:hover {
                     background: $light-gray;
                 }
             }
         }
         &.active .secondary-grouper {
             background: $light-gray;
         }
         > .price {
             justify-self: center;
         }
         input[type=number] {
             max-width: 6rem;
             margin: 0 auto;
         }
     }
     @media (min-width: $breakpoint) {
         grid-template-columns: 1fr 1fr 1fr 2fr 1fr 1fr auto;
         font-size: 1rem;
         > .item .secondary-grouper,
         > .header .secondary-grouper {
             display: contents;
         }
         .secondary-grouper > summary {
             display: none;
         }
         &.drip {
             grid-template-columns: 1fr 1fr 1fr 1fr 1fr auto;
         }
         > .item {
             .column {
                 padding: $base-gap;
             }
             > .title {
                 font-family: "Eurofurence Light";
                 font-size: 1.5rem;
             }
         }
     }
 }
 .exclusive-mark {
     font-size: 0.55rem;
     display: inline-block;
     background: $red;
     color: white;
     border-radius: 0.5em;
     font-family: $body-font-family;
     padding: 0.5em;
     letter-spacing: 0;
 }
 .footer {
     background: white;
     position: sticky;
     bottom: 0;
     display: flex;
     justify-content: space-between;
     align-items: center;
     border-top: 1px solid black;
     z-index: 5;
     flex-flow: column;
     :first-child {
         margin-right: auto;
     }
     > * {
         padding: 0.5rem;
     }
     @media (min-width: $breakpoint) {
         flex-flow: row;
     }
 }

</style>
