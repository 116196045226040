<template>
    <DebtWarning v-if="profile.isFinished" :isDebt="profile.last.value.isDebt" />

    <p class="message" v-if="profile.last.isRunning">
        Loading...
    </p>
    <p class="message" v-else-if="profile.last.isError">
        Помилка завантаження даних профілю: {{ profile.last.error }}
    </p>

    <section v-if="profile.last.isFinished">
        <h2 class="text-align-center">Вміст замовлення</h2>
        <table>
            <tbody>
                <tr v-for="line in order.content">
                    <td class="product-title like-h">{{ line.item }} <span class="font-size-s"><span v-if="line.type != 'drip'">{{ line.bean }}</span><span v-else>drip</span></span></td>
                    <td class="font-size-s">{{ line.quantity.big }} &times; <span class="unit"><span v-if="line.type == 'drip'">пч</span><span v-else>1kg</span></span></td>
                    <td class="font-size-s">{{ line.quantity.small }} &times; <span v-if="line.type == 'drip'">пкт</span><span v-else>250g</span></td>
                    <td>{{ discounted(rowSum(rowsByKey, [line.sku, line]), profile.last.value, line.type) }} UAH</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="3">
                        Загалом
                    </td>
                    <td v-if="totals">{{ totals.sum }} UAH</td>
                </tr>
                <tr>
                    <td colspan="3">
                        Економія
                    </td>
                    <td v-if="totals">{{ totals.full - totals.sum }} UAH</td>
                </tr>
            </tfoot>
        </table>
    </section>

    <section class="margin-vertical-l">
        <h2 class="text-align-center">Доставка і оплата</h2>
        <dl class="details border padding-m">
            <dt>Спосіб оплати</dt>
            <dd v-if="order.payMethod == 'fop'">ФОП</dd>
            <dd v-else-if="order.payMethod == 'post'">При отриманні</dd>
            <dt>Спосіб доставки</dt>
            <dd v-if="order.shipMethod == 'novaposhta'">Нова пошта</dd>
            <dd v-else-if="order.shipMethod == 'svitkavy'">Світ кави (в межах Львова)</dd>
            <dt>Адреса доставки</dt>
            <dd>{{ formatAddress(order.address) }}</dd>
            <dt>Коментар</dt>
            <dd><span v-if="order.coment">{{ order.comment }}</span><span v-else>-</span></dd>
        </dl>
    </section>

    <section class="summary-actions">
        <router-link to="/order/checkout" class="return">&larr; повернутися</router-link>
        <button class="xbutton button-primary" @click="submitOrderTask.perform(order)">Замовити</button>
    </section>

</template>

<script>
 import router from '@/router/index.js';
 import { reactive, ref, watchEffect } from 'vue';
 import {
     offersByKey,
     loadSelection,
     loadOrderDraft,
     selectionList,
     selectionTotals,
     discounted,
     rowSum,
 } from '@/common/selection.js';
 import { getMyProfileTask, submitOrderTask } from '@/common/api.js';
 import formatAddress from '@/common/address.js';
 import Totals from '@/components/Totals.vue';

 export default {
     name: 'Підтвердити замовлення',
     components: {
         Totals,
     },
     setup() {
         const order = loadOrderDraft();
         const rowsByKey = offersByKey();
         const totals = ref(null);

         getMyProfileTask.perform().then(val => {
             const selected = {};
             order.content.forEach(row => selected[row.sku] = row);
             totals.value = selectionTotals(rowsByKey, selected, val);
         });

         return {
             profile: getMyProfileTask,
             submitOrderTask,
             order,
             formatAddress,
             totals,
             discounted,
             rowsByKey,
             rowSum,
             router,
         };
     }
 }
</script>

<style lang="scss">
 .details {
     @media (min-width: 40rem) {
         display: grid;
         grid-template-columns: auto 1fr;
         align-items: baseline;
         grid-gap: 0.5rem;
     }
 }
 .return {
     margin: 1rem;
 }
 
</style>
