import axios from 'axios';
import { applyAuthTokenInterceptor, setAuthTokens, clearAuthTokens } from 'axios-jwt';
import { useTask } from 'vue-concurrency';
import router from '@/router/index.js';
import { clearSelection } from './selection.js';

const BASE_URL = '/api/';

const http = axios.create({
  baseURL: BASE_URL,
});

/*
 * Authentication
 */
const logoutOnFail = error => {
  if (error.response) {
    if (error.response.status == 401) {
      clearAuthTokens();
      router.push("/login");
    };
  }
  return Promise.reject(error);
};

const requestRefresh = refresh => {
  return axios.post(BASE_URL + "token/refresh/", { refresh })
    .then(response => Promise.resolve({
      accessToken: response.data.access,
      refreshToken: response.data.refresh,
    }))
    .catch(logoutOnFail);
};

applyAuthTokenInterceptor(http, { requestRefresh });

http.interceptors.response.use(okay => okay, logoutOnFail);

export const authenticateTask = useTask(function*(signal, login, password, router) {
  const response = yield http.post("token/", {
    username: login,
    password: password,
  }).then(function (response) {
    setAuthTokens({
      accessToken: response.data.access,
      refreshToken: response.data.refresh,
    });
    router.push({ name: "select" });
  }).catch(function (error) {
    if (error.response.status == 401) {
      throw {
        "message": "Логін або пароль вказані неправильно"
      }
    }
  });
});

export const logout = () => {
  clearAuthTokens();
  router.push({ name: "login" });
};

/*
 * Profile and orders
 */
export const getMyProfileTask = useTask(function*() {
  const response = yield http.get("myprofile/");
  return response.data;
});

export const crossCheckTask = useTask(function*() {
  const response = yield http.post("myprofile/crosscheck/");
  return response.data;
});

export const getMyOrdersTask = useTask(function*() {
  const response = yield http.get("myorders/");
  return response.data;
});

export const saveAddressTask = useTask(function*(signal, address) {
  const base = "myprofile/addresses/";
  const endpoint = address.pk ? `${base}${address.pk}` : base;
  const response = yield http.post(endpoint, address);
  return response.data;
});

export const submitOrderTask = useTask(function*(signal, order) {
  const response = yield http.post("myorders/", order)
        .then(function (response) {
          clearSelection();
          router.push({ name: "orders" });
        });
});

export const getMyOffersTask = useTask(function*() {
  const response = yield http.get("myoffers/");
  return response.data;
});

export default http;
