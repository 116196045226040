<template>
    <form class="login max-width-m margin-horizontal-auto" v-on:submit.prevent="authenticateTask.perform(login, password, router)">
        <h2 class="text-align-center margin-l">
            Вхід до бізнес-кабінету Світу Кави
        </h2>
        <p class="color-error border-color-error message border padding-m" v-if="authenticateTask.isError">
            {{ authenticateTask.last.error.message }}
        </p>
        <p class="field">
            <label for="login">Логін</label>
            <input type="text" id="login" name="login" v-model="login" v-bind:disabled="authenticateTask.isRunning" required />
        </p>
        <p class="field">
            <label for="password">Пароль</label>
            <input type="password" id="password" name="password" v-model="password" v-bind:disabled="authenticateTask.isRunning" required />
        </p>
        <div class="form-actions flex-justify justify-content-center">
            <button class="xbutton button-xl" type="submit" v-bind:disabled="authenticateTask.isRunning">Увійти</button>
        </div>
    </form>
</template>

<script>
 import { ref } from 'vue';
 import { useRouter } from 'vue-router';
 import { authenticateTask } from '@/common/api.js';

 export default {
     setup() {
         const login = ref("");
         const password = ref("");
         const router = useRouter();
         
         return {
             login,
             password,
             router,
             authenticateTask,
         }
     }
 }
</script>
