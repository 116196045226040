<template>
    <dl class="totals">
        <dt>Загалом:</dt>
        <dd class="sum">{{ totals.sum }} UAH</dd>
        <dt>Економія:</dt>
        <dd>{{ totals.full - totals.sum }} UAH</dd>
    </dl>
</template>

<script>
 export default {
     props: ['totals'],
 }
</script>

<style lang="scss">
 @import "~@/sass/common";
 .totals {
     display: grid;
     grid-template-columns: auto auto;
     grid-gap: $small-gap;
     padding: 0;
     margin: 0;
     dt, dd {
         margin: 0;
     }
 }
</style>
