<template>
    <DebtWarning v-if="profile.isFinished" :isDebt="profile.last.value.isDebt" />

    <div class="border padding-m margin-m no-margin-horizontal">
        <h3>Обрано каву:</h3>
        <p>{{ selectedList.join(", ") }}</p>
    </div>
    <p class="message" v-if="profile.last.isRunning">
        Loading...
    </p>
    <p class="message" v-else-if="profile.last.isError">
        Помилка завантаження даних профілю: {{ profile.last.error }}
    </p>
    <form v-else @submit.prevent="confirmOrder">
        <p class="field">
            <label for="payment-method">Спосіб оплати</label>
            <div class="select">
                <select name="payment-method" id="payment-method" v-model="payMethod" required>
                    <option value="fop">ФОП</option>
                    <option value="post">При отриманні</option>
                </select>
            </div>
        </p>
        <p class="field">
            <label for="shipping-method">Спосіб доставки</label>
            <div class="select">
                <select name="shipping-method" id="shipping-method" v-model="shipMethod" required>
                    <option value="novaposhta">Нова пошта</option>
                    <option value="svitkavy">Світ кави (у межах Львова)</option>
                </select>
            </div>
        </p>
        <p class="field">
            <label for="shipping-address">Адреса</label>
            <div class="input-group">
                <div class="select flex-1">
                    <select name="shipping-address" v-model="selectedAddress">
                        <option v-for="address in profile.last.value.addresses" :value="address.pk">{{ formatAddress(address) }}</option>
                        <option value="null">Додати нову...</option>
                    </select>
                </div>
                <button v-if="isEditing || !addresses[selectedAddress].pk"
                        class="button-grey save-edit-btn"
                        @click.prevent="saveAddress"
                        :disabled="saveAddressTask.isRunning">Зберегти</button>
                <button v-else class="button-light save-edit-btn" @click.prevent="isEditing = true">Редагувати</button>
            </div>
        </p>
        <fieldset class="border padding-m" v-if="isEditing || !addresses[selectedAddress].pk">
            <legend v-if="addresses[selectedAddress].pk">Редагувати адресу</legend>
            <legend v-else>Додати адресу</legend>
            <div class="address-form-block">
                <p class="field" v-if="shipMethod == 'novaposhta'">
                    <label for="city">Місто</label>
                    <input name="city" id="city" type="text" v-model="addresses[selectedAddress].city" required>
                </p>
                <p class="field" v-if="shipMethod == 'novaposhta'">
                    <label for="novaposhtaOffice">№ відділення Нової пошти</label>
                    <input name="novaposhtaOffice" id="novaposhtaOffice" type="text" v-model="addresses[selectedAddress].novaposhtaOffice" required>
                </p>
                <p class="field street-address" v-else>
                    <label for="address">Вулиця, будинок</label>
                    <input name="address" id="address" type="text" v-model="addresses[selectedAddress].streetAddress" required>
                </p>
                <p class="field">
                    <label for="phone">Прізвище, ім'я, по-батькові отримувача</label>
                    <input name="name" id="name" type="text" v-model="addresses[selectedAddress].name" required>
                </p>
                <p class="field">
                    <label for="phone">№ телефону отримувача</label>
                    <input name="phone" id="phone" type="text" v-model="addresses[selectedAddress].phone" required>
                </p>
                <span class="font-size-small font-style-italic field"><span v-if="saveAddressTask.isFinished">Збережено</span><span v-else-if="saveAddressTask.isError" class="color-error">Помилка збереження змін</span></span>
            </div>
        </fieldset>
        <p class="field">
            <label for="comment">Коментар</label>
            <textarea name="comment" id="comment" v-model="comment"></textarea>
        </p>
        <div class="summary-actions">
            <Totals v-if="totals" v-bind:totals="totals" />
            <button class="xbutton button-primary">Продовжити</button>
        </div>
    </form>
</template>

<script>
 import router from '@/router/index.js';
 import { reactive, ref, watchEffect } from 'vue';
 import { offersByKey, loadSelection, selectionList, selectionTotals, selectionForOrder, storeOrderDraft } from '@/common/selection.js';
 import { getMyProfileTask, saveAddressTask } from '@/common/api.js';
 import formatAddress from '@/common/address.js';
 import Totals from '@/components/Totals.vue';

 function addressesAsMap(addresses = []) {
     const addrMap = {
         null: {
             name: "",
             city: "",
             phone: "",
             novaposhtaOffice: "",
         }
     };
     addresses.forEach(addr => {
         addrMap[addr.pk] = Object.assign({}, addr);
     });
     return addrMap;
 }

 export default {
     name: 'Оформити замовлення',
     components: {
         Totals,
     },
     setup() {
         const rowsByKey = offersByKey();
         const selected = loadSelection(rowsByKey);
         const selectedList = selectionList(rowsByKey, selected);
         const totals = ref(null);

         const payMethod = ref(null);
         const shipMethod = ref("novaposhta");
         const comment = ref("");

         const addresses = ref(addressesAsMap());
         const selectedAddress = ref(null);

         const isEditing = ref(false);

         function fetchProfile() {
             getMyProfileTask.perform().then(val => {
                 addresses.value = addressesAsMap(val.addresses);
                 if (!selectedAddress.value && val.addresses.length) {
                     selectedAddress.value = val.addresses.slice(-1)[0].pk;
                 }
                 totals.value = selectionTotals(rowsByKey, selected, val);
             });
         };

         function saveAddress(reload = true) {
             return saveAddressTask
                 .perform(addresses.value[selectedAddress.value])
                 .then(() => {
                     isEditing.value = false;
                     if (reload) fetchProfile();
                 });
         }

         async function confirmOrder() {
             await saveAddress(false);
             const order = {
                 content: selectionForOrder(rowsByKey, selected),
                 address: addresses.value[selectedAddress.value],
                 payMethod: payMethod.value,
                 shipMethod: shipMethod.value,
                 comment: comment.value,
             };
             storeOrderDraft(order);
             router.push({ name: "confirm" });
         }

         fetchProfile();

         return {
             // tasks
             profile: getMyProfileTask,

             // rest
             addresses,
             comment,
             confirmOrder,
             formatAddress,
             isEditing,
             payMethod,
             shipMethod,
             saveAddress,
             saveAddressTask,
             selected,
             selectedAddress,
             selectedList,
             totals,
         }
     }
 }
</script>

<style lang="scss">
 @import "~@/sass/common";

 .input-group {
     flex-wrap: wrap;
 }
 .save-edit-btn {
     width: 100%;
     @media (min-width: 40rem) {
         width: 16ch;
         margin-left: $base-gap;
     }
 }
</style>
